import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import TimeLine from 'src/components/Dashboard/Views/Pages/TimeLinePage.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Logout from 'src/components/Dashboard/Views/Pages/Logout.vue'

const Signup = () => import(/* webpackChunkName: "Sigunup" */ 'src/components/System/Signup/Signup.vue');

const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ 'src/components/System/ForgotPassword/ForgotPassword.vue');

const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ 'src/components/System/ResetPassword/ResetPassword.vue');

import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'

// Components pages
import Buttons from 'src/components/Dashboard/Views/Components/Buttons.vue'
import GridSystem from 'src/components/Dashboard/Views/Components/GridSystem.vue'
import Panels from 'src/components/Dashboard/Views/Components/Panels.vue'
import SweetAlert from 'src/components/Dashboard/Views/Components/SweetAlert.vue'
import Notifications from 'src/components/Dashboard/Views/Components/Notifications.vue'
import Icons from 'src/components/Dashboard/Views/Components/Icons.vue'
import Typography from 'src/components/Dashboard/Views/Components/Typography.vue'

// Forms pages
const RegularForms  = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/RegularForms.vue')
const ExtendedForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ExtendedForms.vue');
const ValidationForms = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/ValidationForms.vue')
const Wizard = () => import(/* webpackChunkName: "forms" */ 'src/components/Dashboard/Views/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/GoogleMaps.vue')
const FullScreenMap = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/FullScreenMap.vue')
const VectorMaps = () => import(/* webpackChunkName: "maps" */ 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue');

//Settings pages
const Users = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UsersList.vue');
const UserForm = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Users/UserForm.vue');

const Roles = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Roles/RolesList.vue');
const Plans = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Plans/PlansList.vue');

const Offices = () => import(/* webpackChunkName: "settings" */ 'src/components/System/Settings/Offices/OfficesList.vue');


//Plan Setup
const CompanyPlanSetup = () => import(/* webpackChunkName: "CompanyPlanSetup" */ 'src/components/System/CompanyPlan/CompanyPlanSetup.vue');

//Select company page
const SelectCompany = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Companies/SelectCompany.vue');


// Mailbox pages
const Mailbox = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Mailbox/Inbox.vue');
const ViewMail = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Mailbox/ViewMail.vue');

//All Mails pages
const AllMails = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/MailsList.vue');
const MailForm = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/MailForm.vue');
const OpenMails = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/OpenMails.vue');
const ForwardMails = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/ForwardMails.vue');
const TrashMails = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/TrashMails.vue');
const ShredMails = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/AllMails/ShredMails.vue');

const SubscriptionView = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Subscriptions/SubscriptionView.vue');
const RenewSubscription = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Subscriptions/RenewSubscription.vue');
const NewSubscription = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Subscriptions/NewSubscription.vue');
const UpgradeSubscription = () => import(/* webpackChunkName: "selectCompany" */ 'src/components/System/Subscriptions/UpgradeSubscription.vue');

// Transactions Page
const UserTransactions = () => import(/* webpackChunkName: "UserTransactions" */ 'src/components/System/Transactions/UserTransactions.vue');
const AllTransactions = () => import(/* webpackChunkName: "UserTransactions" */ 'src/components/System/Transactions/AllTransactions.vue');


const PaymentMethods = () => import(/* webpackChunkName: "UserTransactions" */ 'src/components/System/PaymentMethods/PaymentMethodsList.vue');

const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ 'src/components/System/Profile/UserProfile.vue');

const CashIn = () => import(/* webpackChunkName: "CashIn" */ 'src/components/System/CashIn/CashIn.vue');
const PayPlan = () => import(/* webpackChunkName: "CashIn" */ 'src/components/System/CashIn/PayPlan.vue');
const VerifyPaymentMethodToken = () => import(/* webpackChunkName: "VerifyPaymentMethodToken" */ 'src/components/System/Verify/VerifyPaymentMethodToken.vue');

const MeetingRoom = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/MeetingRoom.vue');
const MeetingRoomManage = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/MeetingRoomsList.vue');
const MeetingRoomView = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/MeetingRoomView.vue');
const MeetingRoomBookingView = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/Booking/MeetingRoomBookingView.vue');
const MeetingRoomBookings = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/Bookings/MeetingRoomBookingsList.vue');
const ManageMeetingRoomBookings = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/MeetingRoom/Bookings/ManagementBookingsList.vue');


const DeskReservations = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/DeskReservations.vue');
const ManageDesks = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/ManageDesksList.vue');
const DeskReservationView = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/DeskReservationView.vue');
const DeskReservationBookingView = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/DeskReservationBookingView.vue');

const DeskReservationBookings = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/DeskReservationBookings.vue');
const ManageDeskReservationBookings = () => import(/* webpackChunkName: "MeetingRoom" */ 'src/components/System/DeskReservations/DeskReservationManagementBookingsList.vue');


const ClientsList = () => import(/* webpackChunkName: "ClientsList" */ 'src/components/System/Clients/ClientsList.vue');

const PromosList = () => import(/* webpackChunkName: "PromosList" */ 'src/components/System/Settings/Promos/PromosList.vue');

const Holidays = () => import(/* webpackChunkName: "Holidays" */ 'src/components/System/Settings/Holidays/HolidaysList.vue');

const CompanyProfile = () => import(/* webpackChunkName: "CompanyProfile" */ 'src/components/System/CompanyPlan/CompanyProfileForm.vue');

const PayDueAmount = () => import(/* webpackChunkName: "CompanyProfile" */ 'src/components/System/DueAmount/PayDueAmount.vue');

const UserLocationList = () => import(/* webpackChunkName: "CompanyProfile" */ 'src/components/System/UserLocation/UserLocationList.vue');

const DownloadCommissionsReport = () => import(/* webpackChunkName: "UserReferralsList" */ 'src/components/System/UserReferrals/DownloadCommissionsReport.vue');

const UserReferralsList = () => import(/* webpackChunkName: "UserReferralsList" */ 'src/components/System/UserReferrals/UserReferralsList.vue');
const UserReferralCommisions = () => import(/* webpackChunkName: "UserReferralCommisions" */ 'src/components/System/UserReferrals/UserReferralCommissions.vue');

const MyCommissions = () => import(/* webpackChunkName: "MyCommissions" */ 'src/components/System/Profile/MyCommissions.vue');
const ReferredUsers = () => import(/* webpackChunkName: "ReferredUsers" */ 'src/components/System/UserReferrals/ReferredUsersList.vue');


// Calendar
import Calendar from 'src/components/Dashboard/Views/Calendar/CalendarRoute.vue'
// Charts
const Charts = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Charts.vue')

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
}
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }]
}


let SettingsMenu = {
  path: '/settings',
  component: DashboardLayout,
  redirect: '/settings',
  children: [
    {
      path: 'users',
      name: 'Users',
      component: Users
    },
    {
      path: 'users/:id/edit',
      name: 'UserEdit',
      component: UserForm
    },
    {
      path: 'roles',
      name: 'Roles',
      component: Roles
    },
    {
      path: 'plans',
      name: 'Plans',
      component: Plans
    },
    {
      path: 'offices',
      name: 'Offices',
      component: Offices
    },
    {
      path: 'holidays',
      name: 'Holidays',
      component: Holidays
    },
    {
      path: 'promos',
      name: 'Promos',
      component: PromosList
    },
 ]
}

let SelectCompanyMenu = {
  path: '/select-company',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'Companies',
      component: SelectCompany
    },
 ]
}

let CompanyPlanMenu = {
  path: '/setup-plan',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'CompanyPlan',
      component: CompanyPlanSetup
    },
 ]
}

let CompanyProfileMenu = {
  path: '/company',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'profile',
      name: 'CompanyProfile',
      component: CompanyProfile
    },
    {
      path: 'user-location',
      name: 'UserLocation',
      component: UserLocationList
    },
 ]
}

let PayDueAmountMenu = {
  path: '/pay-due-amount',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'PayDueAmount',
      component: PayDueAmount
    },
 ]
}

let UserReferralsMenu = {
  path: '/user-referrals',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'UserReferralsList',
      component: UserReferralsList
    },
    {
      path: ':id/commissions',
      name: 'UserReferralCommisions',
      component: UserReferralCommisions
    },
    {
      path: 'commissions-report',
      name: 'DownloadCommissionsReport',
      component: DownloadCommissionsReport
    },
 ]
}

let UserProfileMenu = {
  path: '/my-profile',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'UserProfile',
      component: UserProfile
    },
 ]
}

let MyCommissionsMenu = {
  path: '/my-commissions',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'MyCommissions',
      component: MyCommissions
    },
 ]
}

let ReferredClientsMenu = {
  path: '/referred-clients',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'ReferredClients',
      component: ReferredUsers
    },
 ]
}

let CashInMenu = {
  path: '/cash-in',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'CashIn',
      component: CashIn
    },
 ]
}

let PayPlanMenu = {
  path: '/pay-plan',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'PayPlan',
      component: PayPlan
    },
 ]
}

let MeetingRoomMenu = {
  path: '/meeting-room',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'search',
      name: 'MeetingRoom',
      component: MeetingRoom
    },
    {
      path: 'manage',
      name: 'MeetingRoomManage',
      component: MeetingRoomManage
    },
    {
      path: 'manage/:id/configure',
      name: 'MeetingRoomView',
      component: MeetingRoomView
    },
    {
      path: 'booking/:code/view',
      name: 'MeetingRoomBookingView',
      component: MeetingRoomBookingView
    },
    {
      path: 'bookings',
      name: 'MeetingRoomBookings',
      component: MeetingRoomBookings
    },
    {
      path: 'manage-bookings',
      name: 'ManageMeetingRoomBookings',
      component: ManageMeetingRoomBookings
    },
 ]
}

let DeskReservationsMenu = {
  path: '/co-working-space',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: 'search',
      name: 'DeskReservations',
      component: DeskReservations
    },
    {
      path: 'manage',
      name: 'ManageDesks',
      component: ManageDesks
    },
    {
      path: 'manage/:id/configure',
      name: 'DeskReservationView',
      component: DeskReservationView
    },
    {
      path: 'booking/:code/view',
      name: 'DeskReservationBookingView',
      component: DeskReservationBookingView
    },
    {
      path: 'bookings',
      name: 'DeskReservationBookings',
      component: DeskReservationBookings
    },
    {
      path: 'manage-bookings',
      name: 'ManageDeskReservationBookings',
      component: ManageDeskReservationBookings
    },
 ]
}

let VerifyPaymentMethodTokenMenu = {
  path: '/verify-payment-method',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'VerifyPaymentMethodToken',
      component: VerifyPaymentMethodToken
    },
 ]
}

let ClientsMenu = {
  path: '/clients',
  component: DashboardLayout,
  redirect: '',
  children: [
    {
      path: '',
      name: 'ClientsList',
      component: ClientsList
    },
 ]
}

let MailboxMenu = {
  path: '/mailbox',
  component: DashboardLayout,
  redirect: '/mailbox/inbox',
  children: [
    {
      path: 'inbox',
      name: 'Mail',
      component: Mailbox
    },
    {
      path: 'inbox/:id/open',
      name: 'ViewMail',
      component: ViewMail
    },
 ]
}

let TransactionsMenu = {
  path: '/transactions',
  component: DashboardLayout,
  redirect: '/transactions/list',
  children: [
    {
      path: 'list',
      name: 'UserTransactions',
      component: UserTransactions
    },
    {
      path: 'manage',
      name: 'AllTransactions',
      component: AllTransactions
    }
 ]
}


let AllMailsMenu = {
  path: '/mails',
  component: DashboardLayout,
  redirect: '/mails/list',
  children: [
    {
      path: 'list',
      name: 'AllMails',
      component: AllMails
    },
    {
      path: 'new',
      name: 'NewMail',
      component: MailForm
    },
    {
      path: 'open',
      name: 'OpenMails',
      component: OpenMails
    },
    {
      path: 'forward',
      name: 'ForwardMails',
      component: ForwardMails
    },
    {
      path: 'trash',
      name: 'TrashMails',
      component: TrashMails
    },
    {
      path: 'shred',
      name: 'ShredMails',
      component: ShredMails
    },
    {
      path: 'list/:id/preview',
      name: 'ViewMailPreview',
      component: ViewMail
    },
 ]
}

let SubscriptionMenu = {
  path: '/subscription',
  component: DashboardLayout,
  redirect: '/subscription/view',
  children: [
    {
      path: 'view',
      name: 'SubscriptionView',
      component: SubscriptionView
    },
    {
      path: 'renew',
      name: 'RenewSubscription',
      component: RenewSubscription
    },
    {
      path: 'new',
      name: 'NewSubscription',
      component: NewSubscription
    },
    {
      path: 'upgrade',
      name: 'UpgradeSubscription',
      component: UpgradeSubscription
    }
 ]
}

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let AccountMenu = {
  path: '/account',
  component: DashboardLayout,
  redirect: '/account/info',
  children: [
    {
      path: 'info',
      name: 'UserInfo',
      component: User
    },
    {
      path: 'payment-method',
      name: 'PaymentMethod',
      component: PaymentMethods
    }
  ]
}

let logoutMenu = {
  path: '/logout',
  component: DashboardLayout,
  redirect: '/logout/user',
  children: [
    {
      path: 'user',
      name: 'Logout',
      component: Logout
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Signup
}

let ForgotPasswordPage = {
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: ForgotPassword
}

let resetPasswordPage = {
  path: '/reset-password',
  name: 'ResetPassword',
  component: ResetPassword
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  ClientsMenu,
  AccountMenu,
  MailboxMenu,
  UserProfileMenu,
  AllMailsMenu,
  SubscriptionMenu,
  SelectCompanyMenu,
  CompanyPlanMenu,
  componentsMenu,
  UserReferralsMenu,
  formsMenu,
  tablesMenu,
  MyCommissionsMenu,
  ReferredClientsMenu,
  SettingsMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  PayDueAmountMenu,
  logoutMenu,
  TransactionsMenu,
  registerPage,
  ForgotPasswordPage,
  resetPasswordPage,
  lockPage,
  CashInMenu,
  PayPlanMenu,
  MeetingRoomMenu,
  DeskReservationsMenu,
  VerifyPaymentMethodTokenMenu,
  CompanyProfileMenu,
  {
    path: '',
    component: DashboardLayout,
    redirect: '/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
